<template>
	<div class="lms-faq">
		<nav-bar :nav-settings="navSettings"></nav-bar>
		<section class="faqs lms-section">
			<div class="lms-content-frame vertical centered">
				<div class="lms-section-head">
					<h2>
						Frequently Asked
						<span>Questions</span>
					</h2>
					<p>{{ getContent.applyPage.faq_subtitle[getLanguage] }}</p>
					<br>
					<div v-for="faq in getContent.faq" :key="faq.question" class="faq" :id="faq.question">
						<div class="content" @click.capture="toggleAnswer">
							<h3 class="question" v-html="faq.question[getLanguage]"></h3>
							<p class="answer hidden" v-html="faq.answer[getLanguage]"></p>
						</div>
					</div>
					<div class="padded-button">
						<router-link tag="button" to="/#contact" class="lms-main-button transparent">
							{{ getContent.applyPage.questions_button[getLanguage] }}
						</router-link>
					</div>

				</div>

			</div>
		</section>
		<Footer></Footer>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import * as ManualCookies from '@/assets/js/cookie-functions.js'

export default {
	components: {
		NavBar,
		Footer,
	},
	computed: {
		getLanguage() {
			return this.$store.state.language;
		},
		getContent() {
			return this.$store.state;
		},
		navSettings() {
			return [
				{
					title: this.getContent.navTitles[0][this.getLanguage],
					path: "/#why-lumos",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">' +
						'<path d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Zm0,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm0,8a1,1,0,0,1,.993.883L12,11v4a1,1,0,0,1-1.993.117L10,15V11A1,1,0,0,1,11,10Zm.01-4a1,1,0,0,1,.117,1.993L11,8a1,1,0,0,1-.117-1.993Z"/>' +
						'</svg>',
					scrollSettings: {
						el: ".why-lumos",
						offset: -120,
					},
				},
				{
					title: this.getContent.navTitles[1][this.getLanguage],
					path: "/#services",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">' +
						'<path d="M19,11a1,1,0,0,1,1,1h0v7a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1h0V12a1,1,0,0,1,1-1h7ZM8,11a1,1,0,0,1,1,1H9v7a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1H0V12a1,1,0,0,1,1-1H8Zm10,2H13v5h5ZM7,13H2v5H7ZM8,0A1,1,0,0,1,9,1H9V8A1,1,0,0,1,8,9H1A1,1,0,0,1,0,8H0V1A1,1,0,0,1,1,0H8ZM19,0a1,1,0,0,1,1,1h0V8a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1h0V1a1,1,0,0,1,1-1h7ZM7,2H2V7H7ZM18,2H13V7h5Z"/>' +
						'</svg>',
					scrollSettings: {
						el: ".our-services",
						offset: 50,
					},
				},
				{
					title: this.getContent.navTitles[2][this.getLanguage],
					path: "/apply",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">' +
						'<path d="M13,12a5,5,0,0,1,5,4.783L18,17v2a1,1,0,0,1-1.993.117L16,19V17a3,3,0,0,0-2.824-2.995L13,14H5a3,3,0,0,0-2.995,2.824L2,17v2a1,1,0,0,1-1.993.117L0,19V17a5,5,0,0,1,4.783-5L5,12Zm7.25.162a5,5,0,0,1,3.745,4.611L24,17v2a1,1,0,0,1-1.993.117L22,19V17a3,3,0,0,0-2.25-2.9,1,1,0,1,1,.5-1.936ZM9,0A5,5,0,1,1,4,5,5,5,0,0,1,9,0Zm7.248.161a5,5,0,0,1,0,9.688,1,1,0,0,1-.5-1.937,3,3,0,0,0,0-5.812,1,1,0,0,1,.5-1.938ZM9,2a3,3,0,1,0,3,3A3,3,0,0,0,9,2Z"/>' +
						'</svg>',
					scrollSettings: {},
				},
				{
					title: this.getContent.navTitles[3][this.getLanguage],
					path: "/blog",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.999" viewBox="0 0 22 19.999">' +
						'<path d="M7,0a4.992,4.992,0,0,1,4,2,4.991,4.991,0,0,1,4-2h6a1,1,0,0,1,1,1h0V16a1,1,0,0,1-1,1H14a2,2,0,0,0-2,2,.949.949,0,0,1-.845.989l-.1.01h-.1A.957.957,0,0,1,10,19h0l-.005-.149A2,2,0,0,0,8,17H1a1,1,0,0,1-1-1H0V1A1,1,0,0,1,1,0H7ZM7,2H2V15H8a3.982,3.982,0,0,1,1.855.455h0l.145.081V5h0l-.005-.176A3,3,0,0,0,7.176,2.005h0ZM20,2H15a3,3,0,0,0-3,3h0V15.535l.145-.08A3.977,3.977,0,0,1,13.8,15h0l.2,0h6Z"/>' +
						'</svg>',
					scrollSettings: {},
				},

				{
					title: this.getContent.navTitles[4][this.getLanguage],
					path: "/team",
					icon:
						'<svg id="Layer_1" viewBox="0 0 91 91" width="44" height="40" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><g>' +
						'<path d="M46.252,22.97c-6.738,0-12.219,5.484-12.219,12.223c0,2.725,0.907,5.236,2.42,7.27c-2.98,2.084-4.777,5.191-4.777,8.521   v17.369h3.4V50.983c0-2.371,1.436-4.619,3.804-6.068c2.052,1.561,4.601,2.498,7.372,2.498c2.806,0,5.385-0.959,7.451-2.557   c2.427,1.449,3.897,3.719,3.897,6.127v17.369H61V50.983c0-3.383-1.839-6.516-4.89-8.598c1.479-2.021,2.363-4.504,2.363-7.193   C58.474,28.454,52.991,22.97,46.252,22.97z M46.252,44.015c-4.863,0-8.818-3.959-8.818-8.822c0-4.865,3.955-8.822,8.818-8.822   c4.864,0,8.822,3.957,8.822,8.822C55.074,40.056,51.116,44.015,46.252,44.015z"/><path d="M18.976,48.233c0-1.043,0.596-2.037,1.579-2.768c1.26,0.838,2.768,1.33,4.391,1.33c4.394,0,7.969-3.572,7.969-7.965   c0-4.395-3.575-7.969-7.969-7.969c-4.393,0-7.965,3.574-7.965,7.969c0,1.529,0.453,2.943,1.202,4.156   c-1.642,1.359-2.607,3.242-2.607,5.246v10.084h3.4V48.233z M24.945,34.263c2.52,0,4.568,2.049,4.568,4.568   c0,2.516-2.049,4.564-4.568,4.564c-2.518,0-4.565-2.049-4.565-4.564C20.38,36.312,22.428,34.263,24.945,34.263z"/><path d="M74.422,42.931c0.729-1.201,1.172-2.596,1.172-4.1c0-4.395-3.574-7.969-7.966-7.969c-4.394,0-7.969,3.574-7.969,7.969   c0,4.393,3.575,7.965,7.969,7.965c1.646,0,3.178-0.502,4.448-1.361c1.01,0.73,1.621,1.738,1.621,2.799v10.084h3.4V48.233   C77.098,46.194,76.106,44.288,74.422,42.931z M67.628,43.396c-2.52,0-4.569-2.049-4.569-4.564c0-2.52,2.05-4.568,4.569-4.568   c2.518,0,4.565,2.049,4.565,4.568C72.193,41.347,70.146,43.396,67.628,43.396z"/>' +
						'</g></svg>',

					scrollSettings: {},
				},
				{
					title: this.getContent.navTitles[5][this.getLanguage],
					path: "/events",
					icon:
						'<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">' +
						'<path fill-rule="evenodd" clip-rule="evenodd" d="M5 1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1V1.89474H17.6315V1C17.6315 0.447715 18.0792 0 18.6315 0C19.1838 0 19.6315 0.447715 19.6315 1V1.93424C22.0948 2.2447 24 4.34723 24 6.89474V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V6.89473C0 4.13331 2.23858 1.89474 5 1.89474V1ZM19 3.89474H5C3.34314 3.89474 2 5.23788 2 6.89473V8.10526H22V6.89474C22 5.23788 20.6569 3.89474 19 3.89474ZM2 19V10.1053H22V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19Z"/>' +
						'</svg>',

					scrollSettings: {},
				},
			];
		},
	},
	metaInfo() {
		return {
			title: 'Lumos - FAQ',
			meta: [
				{ name: 'description', content: 'Frequently Asked Questions' },
			]
		}
	},
	data() {
		return {
			publicPath: process.env.BASE_URL,
		};
	},
	methods: {
		toggleAnswer(event) {
			let answer = event.target.querySelector(".answer")
			if (answer) {
				answer.classList.toggle("hidden")
			} else {
				let answer = event.target.parentElement.querySelector(".answer")
				if (answer) {
					answer.classList.toggle("hidden")
				}
			}
		}
	},
	mounted() {
		//Statistic
		ManualCookies.sendCookies("FAQ");
	}
};
</script>

<style lang="scss">
.lms-faq {
	padding-top: 120px;

	.faqs {
		margin-top: 20px;

		.content {
			border: 1px solid $border-grey;
			border-radius: 10px;
			box-shadow: 0 0 15px $border-grey;
			padding: 20px;
			margin-bottom: 15px;
			font-size: 0;

			.question {
				padding-top: 6px;
				padding-bottom: 6px;
			}

			.answer {
				text-align: center;
				display: inline-block;
				margin: 0;

				max-height: 500px;
				transition: 1s all;
				overflow: hidden;
			}

			.hidden {
				max-height: 0;
			}
		}

		.content:hover {
			background-color: #F5F6F8;
			box-shadow: 0 0 15px darkgray;
		}
	}
}

.padded-button {
	margin: 50px;
}

/* - - - - - - - - - - - - - */
/* -  Mobile Optimization  - */
/* - - - - - - - - - - - - - */

@media screen and (max-width: 768px) {
	.lms-faq {
		padding-top: 90px;

		.faqs {
			.answer {
				max-height: 700px;
				overflow: scroll;

			}

			.hidden {
				max-height: 0;
			}
		}
	}
}
</style>
